import "@babel/polyfill";
import Glide, { Autoplay, Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import ScrollReveal from 'scrollreveal';
import { CountUp } from 'countup.js';
import SimpleLightbox from 'simple-lightbox'; //https://dbrekalo.github.io/simpleLightbox/

import styles from '../css/app.pcss';

import Logo from "../img/logo-target.svg";

// App main
const main = async() => {
    // Import our CSS
    //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');

    const fontawesome = await
    import ( /* webpackChunkName: "fontawesome" */ 'font-awesome/css/font-awesome.min.css');
    const glideCss = await
    import ( /* webpackChunkName: "glide" */ '@glidejs/glide/dist/css/glide.core.css');
    const masonry = await
    import ( /* webpackChunkName: "masonry" */ './masonry.js');
    const Vue = await
    import ( /* webpackChunkName: "vue" */ 'vue');
    const VueCookies = await
    import ( /* webpackChunkName: "vuecookies" */ 'vue-cookies');
    const Scripts = await
    import ( /* webpackChunkName: "scripts" */ './scripts.js');

    // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        components: {
            //'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
        },
        data: {
            openMobile: false,
            cookieConsented: false,
            lang: false,
            page: false,
            redirect: false
        },
        methods: {
            toggleMobile(e) {
                e.preventDefault();
                this.openMobile = !this.openMobile;
            },
            cookieConsent(e) {
                this.cookieConsented = true;
                VueCookies.set('cookieConsent', true);
            }
        },
        mounted() {
            VueCookies.config('7d');

            this.lang = this.$el.getAttribute('data-lang');
            this.page = this.$el.getAttribute('data-page');
            this.redirect = this.$el.getAttribute('data-redirect');

            this.cookieConsented = VueCookies.get('cookieConsent');
        },
    });

    var footerLogoImage = document.getElementById('site-footer__logo');
    if (footerLogoImage) footerLogoImage.src = Logo;
    var headerLogoImage = document.getElementById('site-header__logo');
    if (headerLogoImage) headerLogoImage.src = Logo;


};
// Execute async function
main().then((value) => {

    for (const el of document.querySelectorAll(".glide")) {
        new Glide(el, {
            type: 'slider',
            autoplay: 3500,
            hoverpause: true,
            animationDuration: 1000
        }).mount({ Controls, Breakpoints, Swipe, Autoplay })
    }

    ScrollReveal().reveal('.reveal', {
        distance: '200px',
        delay: 800,
        scale: 0.85
    });

    ScrollReveal().reveal('.content-block--figures', {
        beforeReveal: countStart
    });

    new SimpleLightbox({ elements: '.mosaic a, .glide__slides a' });
});

function countStart() {
    for (const item of document.querySelectorAll(".counter")) {
        var countValue = item.getAttribute('data-value');
        var countUP = new CountUp(item, countValue, {
            useEasing: true,
            useGrouping: true,
            separator: '',
            decimal: '',
        });
        if (!countUP.error) {
            countUP.start();
        }else {
            console.error(countUP.error);
        }
    }
}